.container-brand{
    position: relative;
    padding: 60px;
    @media screen and (min-width: $medium-width) {
        
    }
    a{
        font-family: $font-family--secondary;
        font-size: 14px;
        text-decoration: underline; 
        color: $color-primary;
    }
    .logo-brand{
       width: 150px;
       margin-bottom: 40px
    }
    .subtitle{
        color: $black;
        font-family: $font-family--secondary; 
        font-weight: bold;
        margin-bottom: 20px;
    }
    .title{
        color: $color-primary;
        font-weight: bold;
        font-size: 40px; 
        text-align: center;
    }
    &:after {
        content: '';
        border-color: transparent transparent #333;
        border-width: 0;
        border-style: solid;
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translate(-50%,0);
    }
    &.active,.selected{
        &:after{
            border-width: 0 11px 11px!important;
        }
    }
}
.container-logo{
   text-align: center;
    @media screen and (min-width: $medium-width) {
        text-align: left;
    }
}
.container-parttypes{ 
    margin-top: 20px;
    @media screen and (min-width: $medium-width) {
         margin-top: 0;
    }
    .fa-plus-circle, .fa-minus-circle {
        float: left;
        font-size: 25px; 
    }
    .brand-parttype-header {
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid $gray-base;
        vertical-align: middle;
        font-size: 17px;
        font-weight: normal;
        color: $shark;
        text-transform: uppercase;
        font-family: $font-family--secondary;
        margin-top: 15px;
        text-align: center;
        @media screen and (min-width: $medium-width) {
            font-size: 23px;
        }
        &:hover {
            cursor: pointer;
        }
        .fa{
            margin-right: 10px;
        }
    }
    .brand-parttype{
        min-height: 125px;
        border: 1px solid $gray-light;
        border-top: 0;
        border-left: 0;
        padding: 40px;
        text-align: center;
        border-right: 0;
       
        @media screen and (min-width: $medium-width) {
             border-right: 1px solid $gray-light;
             &:nth-of-type(3n), &:last-of-type{
                border-right: 0;
            }
         }
         @media screen and (min-width: $medium-width) {
            border-right: 1px solid $gray-light;
            &:nth-of-type(3n){
                border-right: 1px solid $gray-light;
            }
             &:nth-of-type(4n), &:last-of-type{
                border-right: 0;
            }
         }
       
        h3{
            text-align: center;
            letter-spacing: 1px;
            color: #000;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 14px;
            height: 45px;
            font-family: $font-family--secondary;
        }
    }
    .brand-parttype-name{
        font-weight: bold;
    }
    .img-product {
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; 
    }
     img{
        margin: 10px;
        width: 100%;
        }
    .row-brand-parttype{
        display: none;
    }
    .brand-subcat-header {
        width: 100%;
        padding: 10px;
        vertical-align: middle;
        border-bottom: 1px solid $gray-light;
        font-size: 14px;
        font-weight: normal;
        color: $gray-base;
        text-transform: uppercase;
        font-family: $font-family--secondary;
        background-color: #eee;
        display: none;
        text-align: center;
        @media screen and (min-width: $medium-width) {
            font-size: 18px;
        }
        &:hover {
            cursor: pointer;
        }
         .fa{
            margin-right: 10px;
        }
    }

}