.l--banner-weathertech{
    margin-bottom: 30px;
    &-header{ 
        position: relative;
        //height: 140px;
        color:white;
        .link-image{
            display: block;
            cursor:pointer;
            &:hover{
                filter: drop-shadow(2px 2px 8px rgba(50, 50, 50, .6));
            }
            img{
                display: block;
                width: 100%;
                height: auto;
                margin-bottom: -20px;
            }
        }
        &----content{
            display: none;
            position: absolute;
            top:50%;
            right:0;
            transform: translate(0,-50%);
            padding: 5px 20px;
        }
        select{
            display: block;
            width: 175px;
            background: white;
            color: black;
            margin: 3px 0;
            padding: 5px;
            border-radius: 3px;
        }
        option{
            color:black;
        }
        .btn{
            padding: 5px 15px;
            min-width: 175px;
            text-align: center;
        }
    }
    &-types{
        //
        border: 3px solid $color-primary;
        padding: 20px;
        overflow: hidden;
        ul{
            @extend %clearfix;
            list-style: none;
            margin:15px -5px 0;
        }
        .title{
            font-size: 20px;
            font-weight: normal;
            border-bottom: 2px solid $color-primary;
            margin-top: 10px;
        }
    }
    &-items{
        box-sizing: border-box;
        
        width: 50%;
        padding: 5px;
        cursor: pointer;
        color: black;
        float: left;
        font-size: 16px;
        font-weight: bold;
        line-height: 2em;
        

        img{
            width: 100%;
            margin-bottom: 5px;
            border: 1px solid $color-primary;
        }

        @media screen and (min-width:$small-width){
            width: 25%;
        }

        @media screen and (min-width: $medium-width){
            width: 16.666%;
            min-height: 220px;
        }
        
    }
    .js--create-weatertech-search{
        display: table;
        margin-top: 10px;
        width: 100%;
        >*{
            display: table-cell;
            //width: 100%;
            padding: 0 5px;
            vertical-align: top;
            &:first-child{
                padding-left: 0;
                width: 75px;
                /*.selectbox{
                    select{
                        width: 75px;
                    }
                }*/
            }
            &:nth-child(2) {
                width: 200px;
            }
            &:last-child{
                padding-right: 0;
            }
            &.btn-holder{
                width: 1px;
            }
        }
        
        .selectbox{
            width: inherit;
            display: block;
        }
        select{
            height: 28px;
            padding-left: 8px;
            padding-right: 30px;
            font-size: 16px;
            border: 1px solid #cacaca;
            border-radius: 3px;
            width: 100%;
        }

        .btn{
            font-size: 16px;
            line-height: 28px;
            height: inherit;
            padding: 0 10px;
            vertical-align: bottom;
            float: right;
        }
    }
}