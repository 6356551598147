.container-partial { 
    &--your-promo { 
       
        padding-bottom: 20px;
        @media screen and (min-width:$medium-width) {
            padding-top: inherit;
        }
        button {
            display: none!important;
        }
        .container-promo img {
            width: 100%;
            max-width: 320px;
            padding: 10px;
        }
    }
    &--help{
        h2{
            font-size: 20px;
        }
    }
}
.container-promo{
    .btn{
        margin-top: 0;
    }
    input.email {
        display: inline-block;
        padding: 10px;
        background-color: #FFF;
        border-radius: 0;
        margin: 0;
        width: 50%;
        min-width: 100px;
        font-family: $font-family--secondary;
        @media screen and (min-width:$medium-width) {
            width: 60%;
        }
    }
    .promo-image{
       width: 100%; 
    }
    strong{
        color: $color-primary;
    }
    .subscribe-newsletter{
        background-color: $gray-light;
    }
    .subtitle{
        font-weight: bold;
        color: $color-secondary;
        font-family: $font-family--secondary;
    }
    .text{
        font-family: $font-family--secondary;
        color: $color-secondary;
        font-size: 12px;
    }
    .text-brands{
        color: $color-primary;
        font-weight: bold;
        font-family: $font-family--secondary;
    }
    .title{
        color: $color-secondary;
        font-weight: 700;
        font-size: 40px;
       
    }
}

.cta-weathertech{
    position: fixed;
    top:50%;
    width: 350px;
    z-index: 99;
    left:-320px;
    transition: all 500ms ease;
    img{
        display: block;
    }
    .btn{
        &:before{
            left: 10.5%;
        }
        padding-left: 40%;
        width: 100%;
        border: 0;
        border-top: 3px solid $white;
        border-bottom: 3px solid $white;
        border-radius: 0;
        box-shadow: 5px 4px 15px 0px black;
    }
    &.active,&:hover{
        left: 0;
    }

    &:after{
        content: '';
        display: block;
        width:120px;
        height: 120px;
        background: url(../images/weathertech/floorliner.png) no-repeat center;
        background-size: contain;
        position: absolute;
        right: -90px;
        top: 50%;
        transform: translateY(-60%);
        filter:drop-shadow(1px 1px 0 $color-primary) drop-shadow(-1px 1px 0 $color-primary) drop-shadow(-1px -1px 0 $color-primary) drop-shadow(1px -1px 0 $color-primary) drop-shadow(2px 4px 6px black)
    }
}
