.btn {
	cursor: pointer;
	position: relative;
	display: inline-block;
	padding: 12px 10px;
	text-decoration: none;
	font-family: $font-family--secondary; 
	font-size: em($base__font-size);
	font-weight: $font-weight--bold;
	color: $white;
    text-align: center;
	vertical-align: middle;
	background: $color-primary;
    border-radius: 50px;
    transform-style: preserve-3d;
	overflow: hidden;
	&.black-btn{
		background: #2a2a32;
	}
	@media only screen and (min-width: $xsmall-width) {
		font-size: em(14px);
	}
    @media only screen and (max-width:$medium-width) {
       //width: 100%;
    }
	
    @media only screen and (min-width: $nohover-width) {
        &:not(.disabled):hover{
            text-decoration: none;

            &:after {
                text-decoration: none;
                opacity: 0;

            }


            &:after {
                opacity: 0.35;

            }
        }
    } 
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: $white;

        @extend %transition-base;
    }
	
	&:focus {
		outline: none;
	}
    &-background-shark{
            background: linear-gradient(0deg, rgba(49,50,54,1) 0%, rgba(49,50,54,1) 63%, rgba(49,50,54,1) 100%); /* w3c */
        }
	&--browse{
		float:left;
		margin-right: 10px;
	}
	
	&--upper {
		text-transform: uppercase;
	}
    
    &--text-centered {
        text-align: center;
    }
    
	&--icon{
		padding-left: 50px;

		&:before {
			position: absolute;
			color: $white;
			top:50%; 
			transform: translateY(-50%);
			font-family: $font-family--icon;
			font-weight: $font-weight--thin;
			z-index: 1;
		}

		&:after {
			padding-left: 20px;
		}

	} // close icon

    &--inverse{
        background: transparent;
        border: 1px solid $color-primary;
        @extend %transition-base;
        color: $shark;
        margin-bottom: 20px;
        &:hover{
            background: $gray-base;
            color: $black;
        }
    }

	&--reset {
		position: relative;
		padding-top: 13px;
		padding-bottom: 13px;
		padding-left: 55px;
		width: 100%;

		&:before {
			content: "\f01e";
			position: absolute;
			left: 12px; top: 5px;
			font-size: 30px;
			font-family: $font-family--icon;
		}

	}

	&--search {
		width: 100%;
		text-transform: uppercase;
		text-align: center;

		padding: 5px;
		vertical-align: middle;

		&:before {
			content: "\f021";
			left: 0;
			font-size: 30px;
			font-family: $font-family--icon;
			padding-right: 20px;
		}

		&[data-js-sticky] {
			min-width: 150px;
			box-shadow:         2px 2px 17px 0px rgba(50, 50, 50, 0.5);
		}
	}

	

	&--view {
		width: 100%;
		text-align: center;

		@media screen and (min-width: $medium-width){
			position: absolute;
			z-index: 150;
			width: 85%;
			top: 0;
		}
		
		&:before {
			content: "\f03a";
			left: 15px;
			font-size: 20px;
			font-family: $font-family--icon;
			margin-right: 15px;
		}
	}

	&--download {
		width: 100%;
		margin: 20px 0;
		&:before {
			content: "\f019";
			left: 15px;
			font-size: 20px;
			font-family: $font-family--icon;
			margin-right: 15px;
		}
	}
    
    &--big {
        font-size: em(18px);
        
        @media only screen and (min-width:$medium-width) {
            font-size: em(14px);
        }
        
        @media only screen and (min-width: $medium-width) {
            font-size: em(16px);
        }
    }
    
    &--left-align {
        text-align: left;
    }s

    &--fluid {
  		width: 100%;
  	}

	&.is-inactive {
        opacity: 0.25;
		cursor: default;
		color: #fff;
		background: rgba(166,166,166,0.5) !important;
	    user-select: none;
	    &:after {
	    	display: none;
	    }
	}

  &__submit {

  	&.profile-account {
  		width: 35%;
  		margin-left: 15px;
  		margin-right: 15px;
  	}

  }

  &__return {
  	float: right;
  }

  &__cancel {
  	text-align: center;

  	&.profile-account {
  		width: 35%;

  	}
  }

  	&-weathertech{
		//padding-left: 50px;
		//position: relative;
		text-align: left;
		border-radius: 0px;
		//
		display: inline-flex;
		flex-wrap: nowrap;
		align-items: center;
		
		&:before{
			content: "";
			display: block;
			width: 40px;
			//height: calc(100% - 10px);
			background: url('../images/weathertech/sticky-hand.png') no-repeat no-repeat center center;
			//position: absolute;
			//left: 5px;
			//top:5px;
			background-size: contain;
			align-self: stretch;
			min-height: 40px;
			margin-right: 10px;
			//opacity: 0.75;
		}
		img{
			max-width: 100%;
		}
		&-black{
			color: $black;
			background-color:$white !important;
			border: 1px solid $black;
			border-radius: 10px;
			&:before{
				background-image: url('../images/weathertech/sticky-hand.black.png');
			}
		}
		&:hover{
			&:before{
				opacity: 1;
			}
		}
	}
}

// Custom buttons

.btn-removal {
	border: 0;
	padding: 4px 8px;
	background-color: $color-warning;
	color: #fff;
	font-size: 12px;
	border-radius: 3px;
	font-weight: $font-weight--bold;
	cursor: pointer;
	@extend %transition-base;

	@media only screen and (min-width: $nohover-width) {
		&:hover {
			background-color: $color-warning-dark;
		}
	}
}
// slick-slider
.icon-arrow {
		background-repeat: no-repeat;
		background-size: 100%;
		transform: scaleX(-1);
		background: $color-primary;
		color: #fff;
		position: relative;
		width: 67px;
		height: 67px;
		opacity: .7;
		display: block;
			border-radius: 50px;
         @extend %transition-base;
		-webkit-transform:scaleX(-1);
		-moz-transform:scaleX(-1);
		-ms-transform:scaleX(-1);
		-o-transform:scaleX(-1);
		transform:scaleX(-1);
		@media screen and (max-width: $medium-large-width) {
			display: none!important;
		}
		&:before {
			content: "\f105";
			font-family: 'FontAwesome';
			font-weight: bold;
			font-size: 75px;
			top: 50%;
			left: 50%;
			transform: translate(calc(5px - 50%),calc(-2px - 50%));
			position: absolute;
			margin: 0;
			padding: 0;
			line-height: 1;
		}
	&--right {
		-webkit-transform:scaleX(-1);
        -moz-transform:scaleX(1);
        -ms-transform:scaleX(1);
        -o-transform:scaleX(1);
    	transform:scaleX(1);
	}
    &:hover{

		opacity: 1;
        
    }
}

.btn-reset{
	border-width: 3px;
	position: relative;
	overflow: visible;
	max-height: 58px;
	z-index: 3;
	color: #004092!important;
	background-color: #fff!important;
	background-image: url(/dist/images/char2.png);
	text-transform: initial;
	background-repeat: no-repeat;
	background-position: 10px 50%!important;
	background-size: auto!important;
	padding-left: 63px!important;
	min-width: 214px;
	text-align: left;
	&:before {
		content: ' ';
		width: 65px;
		height: 65px;
		background: white;
		border-radius: 10px;
		position: absolute;
		z-index: 2;
		display: block;
		right: -13px;
		top: 50%;
		transform: translate(0,-50%) rotate(45deg);
	}
	&:after {
		content: ' ';
		width: calc(100% + 6px);
		height: calc(100% + 6px);
		border: 3px solid $color-primary;
		border-right: 0px solid transparent;
		border-radius: 100px;
		position: absolute;
		background: transparent;
		z-index: 1;
		display: block;
		right: -13px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		opacity: 1;
	}
	&.no-before{
		&:before{
			content:none;
		}
	}
	@media screen and (min-width:$medium-width) {
		margin-right: 20px;
	}
}
