.site-header {
    min-height: 90px;
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: white;
    //height: 149px;
    top: 0;
    @extend %transition-base; 
    box-shadow: 0 0px 20px #000;
    @media screen and (min-width: $medium-width) {
        position: static;
        box-shadow: 0 0 0;
    }
    @media screen and (min-width: $medium-width) {
        width: auto;
        //height: 170px;
        position: relative;
        box-shadow: none;
    }
    
    .divider-menu {
         @media screen and (min-width: $medium-width) {
            border-left: $gray-base 1px solid;
            padding-left: 20px!important;
         }
    }
    .fa {
        font-size: 35px;
        margin-bottom: 5px;
        @extend %transition-base;
        cursor:pointer;
       
    }
    ul:not(.sub-menu){
        >li {
            display: inline-block;
            font-family: $font-family--primary;
            font-size: 14px;
            @media screen and (min-width: $medium-width) {
                font-size: 16px;
                &.menu-item-has-children{
                    position: relative;
                    >ul.sub-menu{
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translate(-50%,0);
                        padding: 10px 10px;
                        border-radius: 0 0 10px 10px;
                        background-color: $gray-xxdark;
                        width: 230px;
                        li{
                            padding: 2.5px 0;
                            a{
                                display: block;
                            }
                        }
                    }
                    &:hover>ul.sub-menu{
                        display: block;
                    }
                }

                &.menu-item-type-cta{
                    margin: -23px -30px -23px 0;
                    padding: 0;
                    float: right;
                    .btn{
                        min-width: 425px;
                    }
                }
            }
            
        }
    }
    
    .link-help {
        &:hover {
            cursor: pointer;
        }
    }
    .container-menu-bar{
        //display: none;
        padding: 0;
        @media screen and (min-width: $medium-width) {
            background-color: $color-primary;
            //height: 30px;
            //position: absolute;
            width: 100%;
            top: 172px;
            display: block;
            padding: 9px;
            margin: 23px 0;
        }
        @media screen and (min-width: $medium-width) {
            top: 142px; 
        }

    }
    .menu {
         position: relative;
        /*@media screen and (min-width: $medium-width) {
            top: -10px;
        }*/
        ul{
            >li {
                margin-right: 0;
                color: $white;
                display: block;
                text-align: center;
                padding: 10px;
                @media screen and (min-width: $medium-width) {
                    padding: 0 20px;
                    
                } 
                &:not(:last-of-type) { 
                    border-bottom: solid 1px $gray-base;
                }
                @media screen and (min-width: $medium-width) {
                    color: $white;
                    display: inline-block;
                    border-bottom: 0!important;
                    &.hidden-md{
                        display: none;
                    }
                }
                @media screen and (min-width: $medium-width) {
                    &:first-of-type {
                        padding-left: 0;
                    }
                }
            }
        }
        &.header-navigation {
            
            ul {
                @extend %clearfix;
            }
            &--primary {

                .icon-nav{
                    border-bottom: 1px solid #878787;
                    @include flex(); 
                    li {
                        border: 0;
                        width: 33%;
                        padding: 10px;
                        i {
                            display: block;
                            margin: 0;
                        }
                    }
                }
                @media screen and (min-width: $medium-width) {
                    //top:-15px;
                    //display: inline-block;
                }
                @media screen and (min-width: $medium-width) {
                    //display: inherit;
                    //top: -5px; 
                } 
            }
            &--secondary {
                /*position: relative;
                text-transform: uppercase;
                text-align: center;
                li {
                    border-bottom: solid 1px $gray-base;
                    display: block;
                    padding: 15px;
                    margin-left: 0;
                    &.link-speed-entry{
                        display: none;
                        @media screen and (min-width: $medium-width) {
                            display: inline; 
                        }
                    }
                }
                @media screen and (min-width: $medium-width) {
                    top: -150px;
                    text-transform: inherit;
                   
                    li {
                        border-bottom: 0;
                        display: inline-block;
                        padding: 0px;
                        margin-left: 20px;
                    }
                 @media screen and (min-width: $medium-width) {
                    text-align: right; 
                    top: -122px;
                    }   
                }*/
                li {
                    color: $white;
                }
            }
        }
    }
    .container {
        position: relative;
        &-social {
            text-align: center;
            margin-bottom: $spacing-base;
            @media screen and (min-width: $medium-width) {
                text-align: right;
            }
        }
    } 
    .header-logo {
        text-align: center;
         margin-bottom: 30px;
         @media screen and (min-width: $medium-width) {
            margin-bottom: 0px;
            margin-right: auto;
        }

        img, svg {
            max-height: 50px;
            //margin-top: -16px;
            @media screen and (max-width: $phone-average) {
                max-height: 45px;
                margin-top: 4px;
                max-width: calc(100% - 60px);
            }
        }
        @media screen and (min-width: $medium-width) {
            text-align: left;
        }
    }
    .header-map{
        .text, img{
            display: inline-block;
            color: $color-secondary;
            font-size: 18px; 
        }
        .text{
            vertical-align: middle;
            font-family: $font-family--secondary;
        }
        .text--first{
            padding-right: 50px;
        }
        img{
            padding-right: 10px;
            padding-left: 20px;
            padding-top: 5px;
            height: 80px;
        }
    }
    .menu__trigger {
        position: absolute;
        right: $spacing-medium-vertical;
        top: $spacing-base;
        @media screen and (min-width: $medium-width) {
            top: $spacing-base-vertical;
        }
        @media screen and (min-width: $medium-width) {
            display: none;
        }
    }
    .nav {
        overflow: hidden;
        position: absolute;
        top: 103px;
        text-align: center;
        left: 0;
        right: 0; 
        background-color: white;
        z-index: 200;
        &.js-segment{
            li{
                &:first-child{
                    border-top-left-radius: 20px;
                }
                &:last-child{
                    border-top-right-radius: 20px;
                }
                @media screen and (max-width: $phone-average) {
                    width: 50%!important;
                }
            }
        }
        /*@media screen and (min-width: $xsmall-width) {
            padding-left: 4px;
            left: 350px;
            top: 158px;
            right: inherit; 
        } */
        @media screen and (min-width: $medium-width) {
            left: inherit;
            right: 10px;
            top: 114px;
        }
        
        @media screen and (min-width: $container-width) {
            left: calc(50% + 244px);
            right: inherit; 
            top: 114px;
        }
        ul {
            list-style: none;
            display: inline-flex;
            width: 100%;
        }
        li {
            display: inline-block;
            margin-right: 2px;
            background-color: $gray-xxdark;
            color: $white;
            @extend %transition-base;
            width: 33%;
            &:last-of-type{
                margin-right: 0;
            }
            @media screen and (min-width: $medium-width) {
                margin-right: 4px;
                width: 120px;
                &:last-of-type{
                    margin-right: 4px;
                }
            }
             @media screen and (min-width: $medium-width) {
                    width: 200px;
                }
            &:hover {
                background-color: $gray-base;
                @extend %transition-base;
            }
            &.active {
                background-color: $color-primary;
                color: $white;
                font-weight: bold;
                 @media screen and (min-width: $medium-width) {
                    width: 200px;
                }
                a {
                    color: $white;
                }
            }
          
        }
        li > a {
            display: block;
            font-size: 17px!important;
            padding: 20px 3px!important;
            text-align: center;
            height: 58px;
            font-weight: 400;
            @media screen and (min-width: $medium-width) {

                padding: 14px 20px;
                font-size: 13px;
            }
             @media screen and (min-width: $medium-width) {
                font-size: 20px;
                padding: 12px 10px;
             }
            &:hover {
                text-decoration: none;
                cursor: pointer;
                color: $white;
            }
            
        }

    }
    .nav__container {
        background-color: $shark;

        @extend %transition-base;
        width: 100%;
        @media screen and (min-width: $medium-width) {
            height: inherit;
            text-align: left;
            top: 0;
            opacity: 1;
            visibility: visible;
            display: block;
            background-color: transparent;
            position:static;
        }
        @media screen and (max-width: $medium-width - 1px) {
            overflow: auto;
            visibility: hidden;
            opacity: 0;
            height: 0;
            &.is-open {
                height: inherit;
                max-height: calc(100vh - 127px);
                visibility: visible;
                opacity: 1;
                overflow: hidden;
            }
        }
        &--secondary {
            background-color: $color-primary;
            display: none;
            height: 40px;
            padding: 10px;
            @media screen and (min-width: $medium-width) {
                display: block;
            }
            li {
                color: $white;
            }
        } 
    }
    &.nav-up {
        top: -150px;
    }

    .container-social{
        margin-left: auto;
        margin-top: -7px;
    }

    .container-policies{
        $color:white;
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            @include flex;
            justify-content: center;
            flex-wrap: nowrap;
        }
        &--item{
            @include flex;
            display: inline-flex;
            align-items: center;
            padding: 0 20px;
            flex-wrap: nowrap;
            min-width: 200px;
        }

        &--img{
            @media screen and (max-width: 1260px) {
                display: none;
            }
            img{
                max-height: 20px;
                width: auto;
            }
            padding-right: 10px;
        }
    }
  
}

.main-header{
    padding: 20px 0;
    form.input-search-bar{
        input[type=search]{
            -webkit-appearance: none;
        }
        button{
            padding:0 10px;
        }
    }
    @media screen and (min-width: $medium-width){
        @include flex;
        align-items: center;
        .search-container{
            flex-grow: 1;
            margin: 0 20px;
        }
    }

    nav{
        li{
            text-align: center;
            padding: 0 5px;
        }
        .fa{
            display: block;
            color: $gray-base;
            font-size: 30px;

            &.fa-red{
                color: $color-secondary;
            }
        }
        a:hover{
            text-decoration: none;
            .fa{
                color: $color-secondary;
            }
        }
    }
}

.sticky-nav{
	position:fixed !important;
	top:0;
	z-index:300;
	width: 100% !important;
	&-placeholder{
		display: block;
		width: auto;
		height: 0;
    }
    
    .main-header{
        padding: 5px 0;
        -webkit-transition: all 250ms ease;
        -moz-transition: all 250ms ease;
        -ms-transition: all 250ms ease;
        -o-transition: all 250ms ease;
        transition: all 250ms ease;
        .header-logo img{
            max-height: 46px;
            -webkit-transition: all 250ms ease;
            -moz-transition: all 250ms ease;
            -ms-transition: all 250ms ease;
            -o-transition: all 250ms ease;
            transition: all 250ms ease;
        }
    }
}