.container-form {
    &.container-partial--login, &.container-partial--forgot-password, &.container-partial--user-account, &.container-checkout, &.container-contact, &.container-confirmation , &.container-partial--registration, &.container-partial--reset-password{ 
        text-align: center;
        padding: 20px;
        font-family: $font-family--secondary;
        @media screen and (min-width:$medium-width) {
            padding: 60px;
        }
        button{
            margin: 10px 0;
        } 

        h1{
            font-size: 35px;
            font-family: $font-family--primary;
            color: $shark; 
            padding-bottom: 20px;
            @media screen and (min-width:$medium-width) {
                font-size: 45px;
            }
        }
        .form-group{
            margin-bottom: 10px;
        }
        label{
            padding-bottom: 5px;
            margin-top: 5px!important;
        }
        .selectbox{
            display: block;
            select{
                width: 100%;
                @media screen and (min-width:$medium-width) {
                    width: 50%;
                }
            }
             &:after {
                margin-top: 40px;
                @media screen and (min-width:$medium-width) {
                    margin-top: 10px;
                    right: 25%;
                    margin-right: 10px; 
                }
            }
        }
    }
    &.container-checkout{
        background-color: $white;
        padding: 30px 0;
        @media screen and (min-width:$medium-width) {
            padding: 20px;
        }
        @media screen and (min-width: $medium-width) {
            padding: 60px;
        }

        .btn{
            width: 50%;
        }

        .form-group{
            input[type=checkbox] {
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;
                @media screen and (min-width: $medium-width) {
                    float: none;
                }
            }
             .label-checkbox{
                 text-align: left;
                @media screen and (min-width: $medium-width) {
                    display: inline-block;
                    text-align: left;
                }
            }
        }
        .store-pick-up{
            display: none;
        }
      .tooltip {
            position: relative;
            display: inline-block;
        }

        .tooltip .tooltiptext {
            visibility: hidden;
            min-width: 200px;
            background-color: $shark;
            color: #fff;
            text-align: left;
            border-radius: 2px;
            padding: 5px;
            position: absolute;
            z-index: 1;
        }

        .tooltip:hover .tooltiptext {
            visibility: visible;
        }
        .wysiwyg-content {
            text-align: right;
            @media screen and (min-width:$medium-width) {
                margin-bottom: 10px;
            }
        }

    }
    &.container-checkout,  &.container-partial--registration{
        .container-secure-shopping{
            background-color: $gray-light;
            padding: 19px; 
            min-height: 200px;
            margin-top: 80px; 
          
            @media screen and (min-width:$medium-width) {
                margin-top: 0;
                margin-left:0;
                max-width: 400px;
                float: right;
            }

            .btn-checkout:disabled {
                background: $gray-base;
                &:hover {
                    cursor: default;
                    &:after{
                        opacity: 0;
                    }
                }
            }
            .item-parttype {
                max-width: 75%;
                word-break: break-word;
                display: inline-block;
            }
             .loader{ 
                background-color: rgba(220,220,220,.75);
                width: 90%;
                position: absolute;
                height: 215px;
                z-index: 10;
            }
            hr{
                background-color: $gray-xlight;
                height: 1px;
                margin: 20px 0;
            }
            .title {
                font-size: 14px;
                margin-bottom: 20px;
                @media screen and (min-width: $medium-width) {
                    font-size: inherit;   
                }  
            }

            sup{
                font-size: 0.5em;
            }
           
         }
        
         label{
            text-align: left;
            padding-left: 0;
            @media screen and (min-width:$medium-width) {
                text-align: right;
            }
            &.text-center{
                text-align: center;
            }
        }

        .logo-stripe, .logo-credit-cards{
            max-width: 175px;
        }
        .selectbox:after{
            @media screen and (min-width:$medium-width) {
                right: 16%;
            }
        }
    }
    &.container-partial--login{
        padding: 0;
        background-color: $white;
        @media screen and (min-width:$medium-width) {
            padding: 60px;
        }
        .container-login {
            background-color: $gray-light;
            margin: auto;
            padding: 10px;
            
            overflow: hidden;
            @media screen and (min-width:$medium-width) {
                border-radius: 25px;
                width: 400px;
            }
        }
        .container-subscription {
            background-color: $gray-base;
            margin: -10px;
            padding: 10px;
            color: white;
        }
        input[type=text], input[type=password], .input-password{
            width: 280px; 
        }
    }
    &.container-partial--user-account{
        background-color: $white;
        .btn{
            @media screen and (min-width:$medium-width) {
                width: 50%;
            }
        }
        .container-profile, .container-address{
            margin-bottom: 50px;
            @media screen and (min-width:$medium-width) {
                padding: 40px 0 20px;
                background-color: $gray-light;
            }
        }
        .email{
            margin: 5px 0 0;
            font-weight: bold;
            padding-left: 0;
            word-break: break-all;
        }
        h1{
            margin-bottom: 20px;
        }
        label{
            text-align: left;
            padding-left: 0;
            @media screen and (min-width:$medium-width) {
                text-align: right;
            }
        }
    }
    form {
        @media screen and (min-width: 500px) {
            margin: 0 auto;
        }
        .form-group {
            @extend %clearfix;
            position: relative;
            label {
                display: block;
                margin: 15px 0 0;
            }
        }
    }
    &.has-error, .error {
        color: $color-warning;
    }
    input[type='text'],input[type='phone'],input[type='search'], input[type='email'], input[type='password'], textarea{
        background-color: $white; 
        border: 1px solid $gray-base;
        color: $black;
        font-family: $font-family--secondary; 
        font-size: 14px;
        padding: 16px 10px;
        @media screen and (min-width: $medium-width) {
                padding: 8px 10px;
            }
    }

    .input-password{
        display: inline-flex;
        align-items: center;
        background-color: $white; 
        border: 1px solid $gray-base;
        color: $black;
        font-family: $font-family--secondary; 
        font-size: 14px;
        input{
            border: 0 !important;
            width: auto !important;
            flex-grow: 1;
        }
        button{
            padding-right: 10px;
        }
    }

    fieldset{
        padding: 5px;
        margin: 20px 0;
        legend,.legend{
            display: block;
            width: 100%;
            text-align: left;
            
            font-size: 20px;
            margin: 10px 0;
            text-transform: uppercase;
            color: $black;
            .fa{
                text-align: right;
                color: $color-primary;
            }
            input[type="checkbox"]{
                width: 20px;
                height: 20px;
                margin: 0 !important;            
            }
            label{
                padding: 0;
                margin: 0 !important;
                text-align: left !important;
            }
        }
        legend{
            float: left;
        }
    }
    .container-card-info{
        background: $gray-xlight;
    }
    .message-sucess{
        padding-bottom: 20px;
        color: $shark;
    }
    .selectbox {
        position: relative;
        vertical-align: middle;
        @extend %transition-base;
        display: block;
        margin-bottom: 10px;
         @media screen and (min-width:$medium-width) {
             display: inline-block;
        }
        select {
            background-color: $white;
            color: $base__font-color;
            border: 0;
            margin: 0;
            height: 50px;
            border: 1px solid rgba($gray-base, 1);
            font-family: $font-family--secondary;
            font-size: 13px;
            @extend %transition-base;
            min-width: 100px;
            padding: 0px 10px;
            width: 100%;
            @media screen and (min-width: $medium-width) {
                padding: 6px 10px;
                 height: 35px;
            }
        }
        &:after,
        &:before {
            content: "";
            position: absolute;
            pointer-events: none;
            cursor: pointer;
            @extend %transition-base;
        }
    }
}

.quantity-control{
    border: 1px solid $gray-light;
    background-color: $white;
    text-transform: uppercase;
    text-align: left;
    padding-left: 5px;
    color: $shark;
    width: 105px;
    font-size: 14px;
    line-height: 30px;
    input[type=number] {
        line-height: inherit;
        height: 30px;
        color: $color-primary;
        font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-weight: 700;
        font-size: 20px;
        width: 60px;
        float: right;
        border: 0 !important;
        outline: 0; 
        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
           opacity: 1;
        }
    }
    &:focus-within{
        outline: 5px auto rgb(94, 158, 214);// CHROME OUTLINE COLOR
    }
    .fa{
        margin-left: 20px;
    }
}

form.input-search-bar{
    @include flex;
    input[type=search]{
        -webkit-appearance: none;
        border: 1px solid $color-primary;
        height: 45px;
        border-radius: 20px 0 0 20px;
        padding: 0 22px;
        flex-grow: 1;
        width: 50px;
        text-overflow: ellipsis;
    }
    button{
        height: 45px;
        width: 80px;
        border-radius: 0 20px 20px 0;
        padding:0 20px;
        background: $color-primary;
        color: $white;
        cursor: pointer;
        .fa,.fa:hover{
            color: $white;
            margin: 0;
        }
        &.close{
            background: $color-secondary;
        }
    }
}