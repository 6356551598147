.menu__trigger {
    display: block;
    position: relative;
    width: 26px;
    height: 14px;

    .line {
        display: block;
        position: absolute;
        left: 0;
        width: 26px;
        height: 2px;
        background: $color-primary;

        @extend %transition-base;

        &.line-1 {
            top: 0;
        }

        &.line-2 {
            top: 50%;
        }

        &.line-3 {
            top: 100%;			
        }
    }
    
    &.is-open {
        .line-1 {
            transform: translateY(7px) translateX(0) rotate(45deg);
        }
        
        .line-2 {
            opacity: 0;
        }
        
        .line-3 {
            transform: translateY(-7px) translateX(0) rotate(-45deg);
        }
    }


    &.menu__cart{
        height: inherit;
        width: inherit;
        top: 50px;
        .fa{
            font-size: 30px;
        }
        .js-menu-cart-count{
            position: absolute;
            top: -10px;
            right: -15px;
            width: 20px;
            height: 20px;
            text-align: center;
            background: $color-primary;
            color: $white;
            border-radius: 50%;
            &:empty{
                display: none;
            }
        }
    }
}