.page-template-tpl-search-php{


    .container-partial {
        padding: 0;
        margin: 0;
    }


}

.categories{
    position: relative;
    width:100%;
    &__category{
        width: calc((100% / 6) - 10px);
        @media screen and (max-width: $phone-average) {
            width: calc((100% / 2) - 10px);
        }
        margin: 13px 5px;
        background-color: #fff;
        border: 1px solid #e1e1e1;
        float: left;
        height: 223px;
        cursor: pointer;
        position: relative;
        display: block;
        &:after{
            content: '';
            border-color: transparent transparent #333 transparent;
            border-width: 0;
            border-style: solid;
            position: absolute;
            bottom: -19px;
            left: 50%;
            transform: translate(-50%, 0);
        }
        &.active{
            &:after{
                border-width: 0px 11px 11px 11px;
            }
        }
        &:hover{
            .categories__category__img-container__img{
                transform: translate(-50%,-50%) scale(1.05,1.05);
            }
        }
        &__img-container {
            position: relative;
            width: 100%;
            height: 178px;
            overflow: hidden;
            display: block;
            top: -22px;
            &__img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: auto;
                max-width: 80%;
                max-height: 178px;
                transition: all .5s ease;
            }
        }
        &__title{
            color: #000;
            width: 100%;
            text-align: center;
            margin: 10px 0;
            font-size: 18px;
            position: absolute;
            left: 0;
            bottom: 0;
            display: block;
        }
    }
}
.fiveRow {
    width: 25%;
    float: left;
}

.container-select-keyword, .container-select-jobs {
    max-width: 740px;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 200px;
    margin: 10px auto;
    height: 97PX;
    position: relative;
    @media screen and (max-width: $phone-average) {
        height:inherit;
    }
    &__logo {
        position: absolute;
        top: 50%;
        left: 33px;
        transform: translate(0,-50%);
    }
    .select2-container--default:after {
       content:initial!important;
    }
    .select2-container{
        width: calc(100% - 150px)!important;
        right: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50px, -50%);
        height: auto;
    }
    .select2-selection__rendered{
        text-align: center;
    }
    .select2-selection{
        margin: 0!important;
    }
}

@media screen and (max-width: $medium-width) {
    .categories__category{
        width: calc((100% / 3) - 10px);
    }
    .fiveRow {
        width: auto;
        float: initial;
    }
    .twoRow {
        width: 50%;
        float: left;
    }
}

@media screen and (max-width: $phone-average) {
    .fiveRow {
        width: auto;
        float: initial;
    }
    .twoRow {
        width: auto;
        float: initial;
    }
    .categories__category{
        width: calc((100% / 2) - 10px);
    }


    .site-header {
        .nav {
            top: 55px!important;
            li {
                width:initial!important;
                padding: 0 7px;
            }
        }
        .header-logo {
            padding: 1px 0 0 7px!important;
            text-align: left!important;
        }
    }
}



.background-section{
    background: #f2f2f2;
    padding: 20px;
}
.displayer{
    &__title{
        text-align: left!important;
        transform: none!important;;
        text-transform: uppercase;
        padding: 32px 20px!important;
        font-size: 20px!important;;
    }
    &__content{
        &__manufacturer{
            &__parttype{
                float: left;
                width: calc(100%/5);
                padding: 10px;
                min-height: 41px;
                border: 1px solid #777;
                border-bottom:1px solid #555;
                border-right:1px solid #222;
                cursor: pointer;
                transition: all .5s ease;
                background: #3a3939;
                color:#fff;
                @media screen and (max-width: $container-width) {
                    width: calc(100%/4);
                }
                @media screen and (max-width:$medium-width) {
                    width: calc(100%/2);
                }
                @media screen and (max-width: $phone-average) {
                    width: 100%//calc(100%/2);
                }
                    &:hover{
                    background: #444;
                }
            }
        }
        &__sub-category{
            padding: 10px 0 0 0;
            min-height: 41px;
            border: 1px solid #777;
            border-bottom:1px solid #555;
            border-right:1px solid #222;
            cursor: pointer;
            transition: all .5s ease;
            background: #3a3939;
            &__text{
                padding: 0 10px;
                margin-bottom: 10px;
                transition: transform .5s ease;
                color:#fff;
                display: block;
            }
            &:hover{
                background: #444;
            }
            &.active{
                background: #444;
                font-weight: bold;
            }
            &__sub-container{
                color:#fff;
                height: 0;
                overflow: hidden;
                transition: all .5s ease;
                font-weight: normal;
                background: #444;
                &.active{
                    .displayer__content__sub-category__sub-container{
                        height: auto;
                    }
                }
                &__button{
                    padding: 11px 0;
                    position: relative;
                    left: 0;
                    transition: all .5s ease;
                    border-bottom: 1px solid #272727;
                    border-top: 1px solid #a7a7a7;
                    &:hover{
                        background: #555;
                    }
                    &:after {
                        top: 0;
                        left: 0;
                        width: calc(100%);
                        height: 1px;
                        content: '';
                        background: #444;
                        position: absolute;
                    }
                    &__text{
                        padding: 0 10px;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.parttype-pop-header, .js-parttype-pop-footer, .js-dropdown-manufacturer{
    display:none!important;
}

.search-top-container{
    padding: 23px 32px;
    margin-bottom: 20px;
    height: 358px;
    background-position: center;
    background-size: cover;
    position: relative;
    @media screen and (max-width: $smaller-width) {
        height: inherit;
        &.search-top-container__right{
            //height: 496px;
        }

    }
    &__elements{

    }
    &__element {
        float: left;
        width: calc(100% / 4 - 16px);
        background: #fff;
        margin-right: 16px;
        cursor:pointer;
        min-height: 233px;
        position: relative;
        overflow: hidden;
        border-radius: 20px;
        transition: all .5s ease;
        max-width: 200px;
        &:hover{
            transform: translate(0,-10px);
        }
        @media screen and (max-width: $medium-medium-large-width) {
            width: calc(100% / 4 - 10px);
            background: #fff;
            margin-left: 5px;
            margin-right: 5px;
        }
        @media screen and (max-width: $smaller-width) {
            width: calc(100% / 2 - 10px);
            min-height: 249px;
            margin-bottom: 10px!important;
            &[data-goto-job]{
                min-height: 100px;
                .search-top-container__element__text{
                    height: auto;
                }
            }
            .btn.btn--upper.mb1 {
                position: absolute;
                bottom: 0;
                width: 96%;
                left: 2%;
            }
        }
        &__img-container {
            min-height: 157px;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: $smaller-width) {
                min-height: 130px;
            }
        }
        &__img {
            left: 50%;
            transition: all .5s ease;
            position: absolute;
            top: 50%;
            max-width: calc(100% + 2px);
            transform: translate(-50%,-50%);
            max-height: 136px;
        }
        &__text {
            color: #000;
            text-align: center;
            /* height: 30px; */
            padding: 23px 0 0 0;
            height: 75px;
        }

    }
    &__filter-view{
        background-color: rgba(0,0,0,.32);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 358px;
        z-index: 1;
        @media screen and (max-width: $smaller-width) {
            height: inherit;

        }
    }
    &__content{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 358px;
        z-index: 2;
        padding: 23px 32px;
        @media screen and (max-width: $smaller-width) {
            height: inherit;
            position: relative;

        }
        @media screen and (max-width: $medium-medium-large-width) {
            padding: 10px;
        }
    }
    h2{
        text-align: left!important;
        font-size: 27px;
        @media screen and (max-width:$smaller-width){
			text-align: center !important;
		}
    }
    &__left{
        h2{
            color:#fff!important;
        }
    }
    &__right{
        background-color: #f2f2f2;
        .search-top-container__element{
            background:transparent;
        }
        .search-top-container__element {
            width: calc(100% / 6 - 16px);
            @media screen and (max-width: $medium-medium-large-width) {
                width: calc(100% / 6 - 1px);
                font-size: 12px;
            }
            @media screen and (max-width: $smaller-width) {
                width: calc(100% / 3 - 10px);
                font-size: 12px;
            }
        }
        .search-top-container__element{
            &:hover{
                .search-top-container__element__img{
                    transform: translate(-50%,-50%) scale(1.05,1.05);
                }
            }
        }
    }
    &__left{
        .search-top-container__element__text--left {
            color:#000;
            text-align: center;
        }
    }
}
@media screen and (max-width: $phone-average) {
    .container-select-keyword__logo{
        display:none;
    }
    .container-select-keyword{
        border:0;
    }
}
